export default [
	'The-Addams-Family',
	'Creature-From-The-Black-Lagoon',
	'Stern-Star-Wars',
	'Elvira',
	'Scared-Stiff',
	'Bride-Of-Pinbot',
	'Pinbot',
	'Banzai-Run',
	'Xenon',
	'Eight-Ball-Deluxe',
];
